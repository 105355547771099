import React from 'react';
import WildPDF from '../functions/wildgamePDF';

const Wildpdfpage = () => {

  return (
      <div className='beefpdf'>
        <WildPDF/>
      </div>
  );
}

export default Wildpdfpage;