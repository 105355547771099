import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SlidingMenu from './slidingmenu';

const Navigation = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 871);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallScreen = window.innerWidth <= 871;

      // Check if the screen size crossed the 871px mark
      if (isSmallScreen !== newIsSmallScreen) {
        window.location.reload(); // Refresh the page
      }

      setIsSmallScreen(newIsSmallScreen);
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSmallScreen]);

  

  useEffect(() => {
    // Extract the pathname from the location object
    const pathname = location.pathname;

    // Update the activePage state based on the current pathname
    if (pathname === '/services') {
      setActivePage('services');
    } else if (pathname === '/about') {
      setActivePage('about');
    } else {
      setActivePage('home');
    }
  }, [location]);

  return (
  <div>
    <div className={`navigation-all ${isScrolled ? 'hidden' : ''}`}>
      {!isSmallScreen && (
        <div className='navigation'>
      <Link to="/services">
        <button className={activePage === 'services' ? 'active' : ''}>
          Services
        </button>
      </Link>
      <Link to="/about">
        <button className={activePage === 'about' ? 'active' : ''}>
          About
        </button>
      </Link>
      <Link to="/">
        <button className={activePage === 'home' ? 'active' : ''}>
          Home
        </button>
      </Link>
      </div>
      )}
      <div className={`hamburger-menu ${isSmallScreen ? 'visible' : ''} `}>
        {/* Render the hamburger button */}
        <button className={`hamburger ${isScrolled ? 'hidden' : ''}`} aria-label="Menu" onClick={toggleMenu}>&#9776;</button>
        {/* Render the sliding menu */}
        
      </div>
    </div>
    <div>
    <SlidingMenu isOpen={isMenuOpen} />
    </div>
  </div>
  );
};

export default Navigation;