import React from 'react';

const BeefPDF = () => {
  return (
    <div>
      {/* Replace '/path/to/your/pdf.pdf' with the actual path to your PDF file */}
      <iframe src="https://docs.google.com/document/d/1N-uGxie6OyAV2MO6vnFprFBJckr0oe5CEY4FNMRYD10" title="Google Doc" className='beef-cut-google'></iframe>
    </div>
  );
};

export default BeefPDF;