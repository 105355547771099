import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home';
import About from './components/pages/About';
import Services from './components/pages/services';
import Beefpdfpage from './components/pages/Beefpdf';
import Wildpdfpage from './components/pages/wildgamecut';
import Porkpdfpage from './components/pages/porkpdf';
import Lambpdfpage from './components/pages/lambpdf';



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />\
          <Route path="/Beef_pdf" element={<Beefpdfpage />} />
          <Route path="/Wild_game_pdf" element={<Wildpdfpage />} />
          <Route path="/Pork_pdf" element={<Porkpdfpage />} />
          <Route path="/Lamb_pdf" element={<Lambpdfpage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;