import React from 'react';
import Navigation from '../functions/navigation';
import logo from '../pages/img/cut sheet logo.png'
import photo from '../pages/img/9073c0761116479fa2f8f9eb24d18949.png'
import Footer from '../functions/footer';
import { Link} from 'react-router-dom';


const Services = () => {


return(
  <div>
      <div className='content'>
        <Navigation />
          <div className='services-info'>
            <h1>
              services
            </h1>
            <p>
              See our cut sheet for specific cuts, pricing and packaging
            </p>
            <div className='cut-sheets'>
              <Link to='/wild_game_pdf' target="_blank" rel="noopener noreferrer">
              <div className='cut-sheet-link'>
                <img src={logo} alt="" className='img-fluid'/>
                <p>
                  Wild Game Cut Sheet
                </p>
              </div>
              </Link>
              <Link to="/Beef_pdf" target="_blank" rel="noopener noreferrer">
              <div className='cut-sheet-link'>
                <img src={logo} alt="" className='img-fluid'/>
                <p>
                  Beef Cut Sheet
                </p>
              </div>
              </Link>
              <Link to='/Pork_pdf' target="_blank" rel="noopener noreferrer">
              <div className='cut-sheet-link'>
                <img src={logo} alt="" className='img-fluid'/>
                <p>
                  Pork Cut Sheet
                </p>
              </div>
              </Link>
              <Link to='/Lamb_pdf' target="_blank" rel="noopener noreferrer">
              <div className='cut-sheet-link'>
                <img src={logo} alt="" className='img-fluid'/>
                <p>
                  Lamb Cut Sheet
                </p>
              </div>
              </Link>
            </div>
            <div className='contact-services'>
              <img src={photo} alt="" className='contact-services-content'/>
              <div className='contact-services-content'>
                <h1>
                  To have your animals processed by Valley Butchery...
                </h1>
                <p>
                  Contact us by phone or email.
                </p>
                <div>
                  <a href="tel:+15096876311">
                    <button>
                      Phone
                    </button>
                  </a>
                  <a href="mailto:wildgameprocessing509@gmail.com">
                    <button>
                      Email
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Footer />
          </div>
      </div>
  </div>

)

}

export default Services