import React from 'react';

const WildPDF = () => {
  return (
    <div>
      {/* Replace '/path/to/your/pdf.pdf' with the actual path to your PDF file */}
      <iframe src="https://docs.google.com/document/d/15UQ2EBoVNbBxoTBATaEkKApcZa9C6KzC5tLLxyjzUn4" title="Google Doc" className='beef-cut-google'></iframe>
    </div>
  );
};

export default WildPDF;