import React from 'react';
import BackgroundContainer from '../functions/backgroundcontainer';
import Navigation from '../functions/navigation';
import logo from "./img/wild game logo.png"
import tray from "./img/meat-tray.png"
import quotes from "./img/quotes.PNG";
import { useEffect, useState } from 'react';
import Footer from '../functions/footer';

const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => { 

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })


return(
  <BackgroundContainer>
      <div className='content'>
        <Navigation className={`header ${isScrolled ? 'hidden' : ''}`}/>
        <div className='logo-container'>
          <div>
            <img src={logo} alt="" className='logo img-fluid'/>
          </div>
          <div>
            <h2>
              Ethical animal processing for Farmers and Private Owners
            </h2>
          </div>
          <div>
            <a href="/about" className="button-info">More Info</a>
          </div>
        </div>
        <div className='about-services'>
          <div className='image-container'>
            <img src={tray} alt="" className='fixed-image img-fluid'/>
          </div>
          <div className='about-home'>
            <h2>About</h2>
            <h5>
            WGP is a local, family-run butchery located in Entiat, WA. We ensure each animal entrusted to us is ethically processed with the highest cleanliness standards. <a href="/about" className='click-here'>Click here</a> to learn more about us!
            </h5>
            <h2>Services</h2>
            <h5>
            We provide butcher services for homesteaders and private owners, specializing in mouth-watering sausages of every kind. <a href="/services" className='click-here'>Click here</a> to see what products we offer!
            </h5>
          </div>
        </div>

        <div className='feedback'>
          <div className='quotes'>
            <div>
              <img src={quotes} alt="" />
            </div>
            <div>
              <h2>Ryan V.</h2>
            </div>
            <div>
              <h5>
              "Me and my family had 3 deer processed here this year and it turned out amazing! The pepperoni, 
              brats & ground he did was some of the best we’ve ever had."
              </h5>
            </div>
          </div>
          <div className='quotes'>
            <div>
              <img src={quotes} alt="" />
            </div>
            <div>
              <h2>Tyler C.</h2>
            </div>
            <div>
              <h5>
              "First time I’ve needed a deer processed since they switched locations 
              and I'm glad to see nothing has changed except the shop. Easy to communicate 
              with and they do a great job making sure you get all of your animal. 
              Nothing wasted. First time having them make pepper sticks for me and 
              they’re awesome. Thanks guys."
              </h5>
            </div>
          </div>
          <div className='quotes'>
            <div>
              <img src={quotes} alt="" />
            </div>
            <div>
              <h2>Heather C.</h2>
            </div>
            <div>
              <h5>
              "He is our go to butcher, worth the drive! You have to try his Peperoni 
              sticks!! We have suggested this business to all friends and family!"
              </h5>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
  </BackgroundContainer>
)

}

export default Home