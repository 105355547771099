import React from 'react';

const PorkPDF = () => {
  return (
    <div>
      {/* Replace '/path/to/your/pdf.pdf' with the actual path to your PDF file */}
      <iframe src="https://docs.google.com/document/d/1ZWwF_tTCz_oig16rqH4sayg4QxMURzwnXt0kG9mj5EE" title="Google Doc" className='beef-cut-google'></iframe>
    </div>
  );
};

export default PorkPDF;