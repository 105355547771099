import React from 'react';
import Navigation from '../functions/navigation';
import photo1 from '../pages/img/what_we_do_img.png'
import photo2 from '../pages/img/childers_family_about_photo.webp'
import photo3 from '../pages/img/more_info_img.png'
import Footer from '../functions/footer';
import Allimages from '../functions/gallery'

const About = () => {


return(
  <div>
      <div className='content'>
        <Navigation />
        <div className='about-header'>
          <div>
            <h2>
              About Valley Butchery
            </h2>
          </div>
          <div>
            <p>
              Providing ethical and humane animal processing for Farmers and Private Owners
            </p>
          </div>
        </div>
        <div className='about-page-info'>
          <div className='about-box'>
            <div className='about-content'>
              <h2>
                What we do
              </h2>
              <p>
                We butcher homestead animals: lamb, pigs, and cows. All 
                meat is complementary vacuum packed with the 
                exception of the ground burger and bulk sausage, 
                we also butcher Wild Game: deer, bear, elk during hunting season
              </p>
              <p>
              Our specialty is making sausage - summer sausage,  
              link sausage, such as polish and german sausage, 
              and pepperoni sticks in a variety of flavors. Our 
              smokers make delicious smoked sausage and the 
              treats you get from your wild game or homestead 
              animals taste amazing. 
              </p>
              <p>
              We can also make sausage from wild birds if the 
              meat is provided by you.
              </p>
              <p>
              Please see our <a href="/services" >cut sheet</a> for more details pertaining 
              to wild game, beef, pork and lamb cuts, pricing and packaging.
              </p>
            </div>
            <div>
              <img src={photo1} alt="" className='img-fluid'/>
            </div>
          </div>
          <div className='about-box-2'>
            <div className='about-content'>
              <img src={photo2} alt="" className='img-fluid'/>
            </div>
            <div className='about-content'>
              <h2>
                who we are
              </h2>
              <p>
                Hunting is something I grew up doing. I harvested my 
                first deer when I was 13 years old. My family always 
                butchered and packaged our own deer, bear, and elk. 
                Because we love homegrown meat, raising lambs and 
                chickens for my family is also a regular part of our lives. 
              </p>
              <p>
                Butchering is part of the process I absolutely enjoy. For 
                many years I have been looking for the opportunity to become 
                a butcher. Buying the business in 2021 was a dream come true. 
              </p>
              <p>
                Thank you, Jeff Tuttle, for all the training and laughs. Wild 
                Game Processing is run by my entire family especially during 
                the busy season. We look forward to serving you for many years to come.
              </p>
              <p>
                Collin Childers and Family
              </p>
            </div>
          </div>
          <div className='more-info'>
            <div className='about-contact'>
              <h2>
                more info 
              </h2>
              <p>
                Contact us for more information or to get our cut sheet
              </p>
              <button>
                Contact Us
              </button>
            </div>
            <div className='about-contact'>
              <img src={photo3} alt="" className='img-fluid' />
            </div>
          </div>


        </div>
          <div>
            <Allimages/>
          </div>
        <div>
          <Footer />
        </div>
      </div>
  </div>

)

}

export default About