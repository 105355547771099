import React from 'react';
import LambPDF from '../functions/lambPDF';

const Lambpdfpage = () => {

  return (
      <div className='beefpdf'>
        <LambPDF/>
      </div>
  );
}

export default Lambpdfpage;