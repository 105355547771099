import logo from "../pages/img/logo.png"
import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import axios from 'axios'

const Footer = () => {

    const [isClicked, setIsClicked] = useState(false);
  
    const handleClick = () => {
      setIsClicked(true);
    };
  
    const handleBlur = () => {
      setIsClicked(false);
    };

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      subject: '',
      comment: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post('http://localhost:5000/api/send', formData);
        alert('Email sent successfully');
        setFormData({ name: '', email: '', phone: '', subject: '', comment: '' });
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email');
      }
    };

  return (
    <div className="footer-container">
      <div className="footer-sec">
        <div>
          <img src={logo} alt="" className="footer-logo"/>
        </div>
        <div className="bold-p2">
          <p>
            Location:
          </p>
        </div>
        <div>
          <p>
            135 Sunshine Lane
          </p>
          <p>
            Entiat, WA 98822
          </p>
          <p>
            wildgameprocessing509@gmail.com
          </p>
          <p>
            509-687-6311
          </p>
        </div>
        <div className="bold-p2">
          <p>
            Hours:
          </p>
        </div>
        <div>
          <p>
            please contact us to visit the butcher shop!
          </p>
        </div>
        <div className='wgp-insta'>
          <div>
            <a href="https://www.instagram.com/wildgameprocessing509/" target="_blank" rel="noopener noreferrer" className='social-icons'>
              <FaInstagram />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/people/Wild-Game-Processing/100076849371422/" target="_blank" rel="noopener noreferrer" className='social-icons'>
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-sec">
        <h1>
          Cut Sheet
        </h1>
        <h4>
          Download our cut sheet for specific information on services and what we offer 
        </h4>
        <Link to="/services">
        <button>
          See our cut sheet
        </button>
        </Link>
      </div>
      <div className="footer-sec">
        <h1>
          Contact Us
        </h1>
        <h4>
          For more information, reach out
        </h4>
      <form onSubmit={handleSubmit}>
        <div className="inputs">
          <input
          type="text"
          name="name"
          placeholder="Your Name"
          className={`custom-input ${isClicked ? 'input-clicked' : ''}`}
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          required
          />
          <input
          type="tel"
          name="phone"
          placeholder="Phone"
          className={`custom-input ${isClicked ? 'input-clicked' : ''}`}
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          required
          />
        </div>
        <div>
          <input
          type="email"
          name="email"
          placeholder="Email"
          className={`custom-input ${isClicked ? 'input-clicked' : ''}`}
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          required
          />
          <input
          type="text"
          name="subject"
          placeholder="Subject"
          className={`custom-input ${isClicked ? 'input-clicked' : ''}`}
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          />
        </div>
        <input
        type="text"
        name="comment"
        placeholder="Message"
        className={`custom-input-message ${isClicked ? 'input-clicked' : ''}`}
        onChange={handleChange}
        onClick={handleClick}
        onBlur={handleBlur}
        />
        <div>
          <button>Submit</button>
        </div>
      </form>
      </div>
    </div>
  )
}

export default Footer