import React from 'react';

const LambPDF = () => {
  return (
    <div>
      {/* Replace '/path/to/your/pdf.pdf' with the actual path to your PDF file */}
      <iframe src="https://docs.google.com/document/d/1DQyErgntqqX0vKq4Pi7uCl37ikbE8zJbGENTVlcbdDc" title="Google Doc" className='beef-cut-google'></iframe>
    </div>
  );
};

export default LambPDF;