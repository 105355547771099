import React from 'react';
import PorkPDF from '../functions/porkPDF';

const Porkpdfpage = () => {

  return (
      <div className='beefpdf'>
        <PorkPDF/>
      </div>
  );
}

export default Porkpdfpage;