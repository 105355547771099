import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const ImageGallery = () => {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const displayedCount = 5;

    useEffect(() => {
        const fetchedImages = [
            { id: 1, lowResSrc: require('./img/low_img_1.webp'), highResSrc: require('./img/img_1.webp'), alt: 'Image 1' },
            { id: 2, lowResSrc: require('./img/low_img_2.webp'), highResSrc: require('./img/img_2.webp'), alt: 'Image 2' },
            { id: 3, lowResSrc: require('./img/low_img_3.webp'), highResSrc: require('./img/img_3.webp'), alt: 'Image 3' },
            { id: 4, lowResSrc: require('./img/low_img_4.webp'), highResSrc: require('./img/img_4.webp'), alt: 'Image 4' },
            { id: 5, lowResSrc: require('./img/low_img_5.webp'), highResSrc: require('./img/img_5.webp'), alt: 'Image 5' },
            { id: 6, lowResSrc: require('./img/low_img_6.webp'), highResSrc: require('./img/img_6.webp'), alt: 'Image 6' },
            { id: 7, lowResSrc: require('./img/low_img_7.webp'), highResSrc: require('./img/img_7.webp'), alt: 'Image 7' },
            { id: 8, lowResSrc: require('./img/low_img_8.webp'), highResSrc: require('./img/img_8.webp'), alt: 'Image 8' },
            { id: 9, lowResSrc: require('./img/low_img_9.webp'), highResSrc: require('./img/img_9.webp'), alt: 'Image 9' },
            { id: 10, lowResSrc: require('./img/low_img_10.webp'), highResSrc: require('./img/img_10.webp'), alt: 'Image 10' },
            { id: 11, lowResSrc: require('./img/low_img_11.webp'), highResSrc: require('./img/img_11.webp'), alt: 'Image 11' },
            { id: 12, lowResSrc: require('./img/low_img_12.webp'), highResSrc: require('./img/img_12.webp'), alt: 'Image 12' },
            { id: 13, lowResSrc: require('./img/low_img_13.webp'), highResSrc: require('./img/img_13.webp'), alt: 'Image 13' },
            { id: 14, lowResSrc: require('./img/low_img_14.webp'), highResSrc: require('./img/img_14.webp'), alt: 'Image 14' },
            { id: 15, lowResSrc: require('./img/low_img_15.webp'), highResSrc: require('./img/img_15.webp'), alt: 'Image 15' },
            { id: 16, lowResSrc: require('./img/low_img_16.webp'), highResSrc: require('./img/img_16.webp'), alt: 'Image 16' },
        ];

        setImages(fetchedImages);
    }, []);

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const nextImages = () => {
        if (currentIndex + displayedCount < images.length) {
            setCurrentIndex(currentIndex + displayedCount);
        }
    };

    const prevImages = () => {
        if (currentIndex - displayedCount >= 0) {
            setCurrentIndex(currentIndex - displayedCount);
        }
    };

    const displayedImages = images.slice(currentIndex, currentIndex + displayedCount);

    return (
        <div className='image-gallery'>
                <button onClick={prevImages} disabled={currentIndex === 0} className='gallery-button'> <MdKeyboardArrowUp /></button>
            <div className='gallery-container'>
                {displayedImages.map((image, index) => (
                    <img
                        key={image.id}
                        src={image.lowResSrc}
                        alt={image.alt}
                        className='gallery-image'
                        onClick={() => openModal(image)}
                    />
                ))}
            </div>
            <div className='navigation-buttons'>
                <button onClick={nextImages} disabled={currentIndex + displayedCount >= images.length} className='gallery-button'> <MdOutlineKeyboardArrowDown /> </button>
            </div>
            {isModalOpen && selectedImage && (
                <div className={`modal ${isModalOpen ? 'isopen' : ''}`}>
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={selectedImage.highResSrc} alt={selectedImage.alt} className={`image-big ${isModalOpen ? 'isopen' : ''}`} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageGallery;