import React from 'react';
import BeefPDF from '../functions/beefPDF';

const Beefpdfpage = () => {

  return (
      <div className='beefpdf'>
        <BeefPDF/>
      </div>
  );
}

export default Beefpdfpage;